<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Список пользователей панели</h4>
            <div class="search-wrap md-layout">
              <md-field
                class="table-input md-layout-item md-large-size-10"
                :md-clearable="true"
                @md-clear="clearHandler('id')"
              >
                <label>ID</label>
                <md-input v-model="searchId" @keyup.enter="searchHandler" />
              </md-field>

              <md-field
                class="table-input md-layout-item md-large-size-20"
                :md-clearable="true"
                @md-clear="clearHandler('fio')"
              >
                <label>ФИО</label>
                <md-input v-model="searchName" @keyup.enter="searchHandler" />
              </md-field>

              <md-field
                class="table-input md-layout-item md-large-size-20"
                :md-clearable="true"
                @md-clear="clearHandler('phone')"
              >
                <label>Номер телефона</label>
                <md-input
                  v-model="searchPhone"
                  md-counter="false"
                  maxlength="18"
                  @keyup.enter="searchHandler"
                />
              </md-field>

              <md-field
                class="table-input md-layout-item md-large-size-20"
                :md-clearable="true"
                @md-clear="clearHandler('email')"
              >
                <label>E-mail</label>
                <md-input v-model="searchEmail" @keyup.enter="searchHandler" />
              </md-field>

              <md-button
                class="md-raised md-primary md-layout-item md-large-size-10"
                @click="searchHandler"
              >
                Поиск
              </md-button>
            </div>
          </div>
        </md-card-header>
        <md-card-content>
          <AdminsTable
            @sort="sortAdmins"
            @prev="goToPage('prev')"
            @next="goToPage('next')"
          />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import AdminsTable from "@/components/Tables/AdminsTable";

import { mapActions } from "vuex";

export default {
  components: {
    AdminsTable,
  },

  data() {
    return {
      searchId: null,
      searchName: null,
      searchPhone: null,
      searchEmail: null,
      sortBy: "id",
      sortOrder: "asc",
      currentPage: 1,
    };
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);

    await this.searchHandler();

    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("admins", ["searchAdmins"]),

    clearHandler(fieldName) {
      switch (fieldName) {
        case "id":
          this.searchId = null;
          break;
        case "fio":
          this.searchName = null;
          break;
        case "phone":
          this.searchPhone = null;
          break;
        case "email":
          this.searchEmail = null;
          break;
        default:
          break;
      }

      this.searchHandler();
    },

    goToPage(direction) {
      direction == "prev" ? this.currentPage-- : this.currentPage++;
      this.searchHandler(false);
    },

    async searchHandler(toFirstPage = true) {
      this.$store.commit("SET_SHOW_LOADER", true);

      if (toFirstPage) {
        this.currentPage = 1;
      }

      await this.searchAdmins({
        id: this.searchId,
        name: this.searchName,
        phone: this.searchPhone?.replaceAll(/[-() ]/g, ""),
        email: this.searchEmail,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
      });

      this.$store.commit("SET_SHOW_LOADER", false);
    },

    sortAdmins(params) {
      this.sortBy = params.sortBy;
      this.sortOrder = params.sortOrder;
      this.searchHandler(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.md-layout {
  max-width: 100%;
}

.search-wrap {
  display: flex;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  & > *:not(:first-child) {
    margin-left: 8px;
  }

  .md-button {
    width: 20%;
    height: max-content;
    flex-shrink: 0;
    align-self: center;
  }

  .md-field {
    .md-input,
    label {
      color: white !important;
      -webkit-text-fill-color: white !important;
    }

    &::v-deep {
      .md-input {
        color: white !important;
        -webkit-text-fill-color: white !important;
      }

      .md-clear {
        background: transparent !important;
        box-shadow: none;
        top: 18px;

        i > svg {
          fill: white !important;
        }
      }
    }
  }
}
</style>
